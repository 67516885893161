import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Box,
  // ButtonList,
  Container,
  Flex,
  Heading,
  Kicker,
  Section,
  Subhead,
  Text,
} from "../ui"
import useHero from "./useHero"
import { theme } from "../../theme.css"

const StyledSection = styled(Section)`
  min-height: 70vh;
  height: 100%;
  display: grid;
  align-items: center;
`

const WhiteSpaceSpan = styled.span`
  white-space: pre-line;

  & a {
    color: ${theme.colors.text};
    background-color: ${theme.colors.muted};
    font-weight: 600;
    text-decoration: none;
    padding: 1px 3px;
    border-radius: 4px;
    transition: background-color 200ms ease-in-out;
    // border-top: 4px dotted ${theme.colors.muted};
    // border-bottom: 4px dotted ${theme.colors.muted};
  }
  & a:hover {
    color: ${theme.colors.active};
    background-color: transparent;
    border-radius: 0;
    // border-top: 4px dotted ${theme.colors.active};
    border-bottom: 4px dotted ${theme.colors.active};
    padding: 0px 3px;
    // transition: all 100ms 200ms ease-in-out;
  }
`

export default function Hero(props) {
  const { image, kicker, h1, subhead, text } = props
  useHero()

  return (
    <StyledSection id="hero">
      <Container>
        <Flex gap={4} variant="responsive">
          {image && (
            <Box width="half" id="hero--image">
              <GatsbyImage
                alt={image.alt}
                image={getImage(image.gatsbyImageData)}
              />
            </Box>
          )}

          <Box width="full">
            <Heading
              as="h1"
              id="hero--heading"
              style={{ visibility: "hidden" }}
            >
              {kicker && (
                <Kicker id="hero--kicker" style={{ visibility: "hidden" }}>
                  {kicker}
                </Kicker>
              )}
              {h1}
            </Heading>
            <Subhead
              as="h2"
              id="hero--subhead"
              style={{ visibility: "hidden", maxWidth: 450 }}
            >
              {subhead}
            </Subhead>
            <Text
              as="p"
              id="hero--text--p"
              style={{ visibility: "hidden", maxWidth: 500 }}
            >
              <WhiteSpaceSpan dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
            <div id="hero--button-list" style={{ visibility: "hidden" }}>
              {/* <ButtonList links={links} /> */}
            </div>
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  )
}

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    kicker
    h1: heading
    subhead
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
