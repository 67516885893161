import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Section, Text, SuperHeading } from "../ui"
import * as styles from "./about-hero.css"
import useAboutHero from "./useAboutHero"

const HeadingWrapper = styled.div`
  overflow: hidden;
`

export default function AboutHero(props) {
  useAboutHero()

  return (
    <Section id="about-hero">
      <Container>
        <HeadingWrapper id="about-hero--heading-wrapper">
          <div id="about-hero--heading" style={{ visibility: "hidden" }}>
            <SuperHeading className={styles.aboutHeroHeader}>
              {props.heading}
            </SuperHeading>
          </div>
        </HeadingWrapper>
        {props.text && (
          <Text
            className={styles.aboutHeroText}
            id="about-hero--text"
            style={{ visibility: "hidden" }}
          >
            {props.text}
          </Text>
        )}
      </Container>
      <Container width="wide">
        {props.image && (
          <GatsbyImage
            alt={props.image.alt}
            image={getImage(props.image.gatsbyImageData)}
            className={styles.aboutHeroImage}
          />
        )}
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment AboutHeroContent on AboutHero {
    id
    heading
    text
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
