import { useEffect } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const enterTl = gsap.timeline({ delay: 0.25 })

const headingClassName = "#about-hero--heading"
const textClassName = "#about-hero--text"

const useAboutHero = () => {
  const handleEnter = () => {
    enterTl.to(`${headingClassName}, ${textClassName}`, {
      visibility: "visible",
    })
    enterTl.from(headingClassName, {
      opacity: 0,
      y: 200,
      rotate: "-5deg",
      duration: 0.75,
    })
    enterTl.from(textClassName, { opacity: 0 })
    enterTl.progress(1)
  }

  // Handles initial rendering
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    handleEnter()
  }, [])

  // Handles the scroll-driven animations
  useEffect(() => {
    const exitTl = gsap.timeline({
      scrollTrigger: {
        trigger: headingClassName,
        scrub: 0.5,
        start: 0,
      },
      defaults: { ease: "power1.out" },
    })

    exitTl.to(headingClassName, { y: 100, opacity: 0 })
    exitTl.to(textClassName, { x: "50%", y: 30 }, "<")
    exitTl.to("#about-hero--heading-wrapper", { overflow: "visible" }, "<")
  }, [])
}

export default useAboutHero
