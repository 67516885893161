import { useEffect } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const SCROLL_TRIGGER_CONFIG = {
  scrollTrigger: {
    trigger: "#logo-list",
    scrub: 0.75,
    start: 0,
    end: "+=500",
  },
  defaults: { ease: "power1.out" },
}

const useLogoList = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline(SCROLL_TRIGGER_CONFIG)

    tl.to("#logo-list", { visibility: "visible" })
    tl.from("#logo-list--text", { opacity: 0, duration: 0.5 })
    tl.from(".logo-list--li", {
      opacity: 0,
      duration: 0.5,
      stagger: 0.25,
    })
  }, [])
}

export default useLogoList
