import { useEffect, useState, useCallback } from "react"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useEvent } from "react-use"

const textClassNames = "#hero--heading, #hero--subhead, #hero--text--p"
const fadeInClassNames = "#hero--kicker, #hero--button-list"
const allClassNames = `${textClassNames}, ${fadeInClassNames}, #hero--image`

const enterTl = gsap.timeline({ defaults: { ease: "power1.out" } })

const useHero = () => {
  const [trackScroll, setTrackScroll] = useState(false)

  const handleEnter = () => {
    const textEnterConfig = { opacity: 0, duration: 0.5, y: 20, stagger: 0.15 }
    const fadeInConfig = { opacity: 0, duration: 1.5 }
    enterTl.from(textClassNames, textEnterConfig)
    // enterTl.from("#hero--image", textEnterConfig, "<")
    enterTl.from(fadeInClassNames, fadeInConfig)
  }

  const onScroll = useCallback(() => {
    setTrackScroll(true)
    enterTl.progress(1)
  }, [])

  useEvent("scroll", onScroll)

  // Initializes to reveal once font families are loaded
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.to(allClassNames, { visibility: "visible" })
  }, [])

  // Handles initial rendering of hero
  useEffect(() => {
    handleEnter()
  }, [])

  // Handles the scroll-driven animations
  useEffect(() => {
    if (!trackScroll) return

    const exitConfig = {
      stagger: 0.05,
      y: -40,
      opacity: 0,
      ease: "power1.inOut",
      scrollTrigger: { trigger: "#logo-list", scrub: 0.5},
    }
    gsap.to(allClassNames, exitConfig)
  }, [trackScroll])
}

export default useHero
